import React from "react"

import Layout from "../../layouts"
import Header from "../../components/header"
import Footer from "../../components/footer"
import SEO from "../../components/seo"
import Helmet from "react-helmet"
import { Hero } from "../../components/self-serving-landing-page/components/Hero"
import { ColorSchemeProvider } from "../../components/self-serving-landing-page/color-scheme-provider"
import { dataHero } from "../../components/calculator/data"


const heroWrapperCss = theme => ({
  "&&": {
    div: { maxWidth: "52rem" },

    h1: {
      backgroundColor: theme.colors.gatsby,
      backgroundImage: "none",
      color: theme.colors.gatsby,
      maxWidth: "32ch",
    },
  },
})


const uCalc_368628 = _theme => ({
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
})


function SupportPage({ location }) {
  return (
    <Layout>
      <SEO
        title="Gatsby Salary Calculator"
        description="Shape the future of Gatsby. Find your salary."
        url={location.href}
        shouldIndex={true}
      />
      <Helmet>
        <script src="/calc-script.js" type="text/javascript"></script>
      </Helmet>
      <Header />
      <ColorSchemeProvider>
        <div css={heroWrapperCss}>
          <Hero {...dataHero} />
          <div className="uCalc_368628" css={uCalc_368628}>

          </div>
        </div>

  
      </ColorSchemeProvider>
      <Footer />
    </Layout>
  )
}

export default SupportPage
